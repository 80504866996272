<script setup lang="ts">
import EldonVerticalAlt1ColorReactive from 'assets/logos/color/Eldon_Vertical-Alt-1-Color-Reactive.svg'
import ContactInfo from '~/components/ContactInfo.vue'

interface ErrorObject {
  url: string
  statusCode: number
  statusMessage: string
  message: string
  description: string
  data: unknown
}

const props = defineProps<{
  error: ErrorObject
}>()

const handleError = () => clearError({ redirect: '/' })

// eslint-disable-next-line no-console
console.error(props.error)
</script>

<template>
  <NuxtLayout name="error">
    <nav>
      <div class="relative z-40 w-full bg-white shadow">
        <div class="container mx-auto px-10 sm:px-6 lg:px-8 xl:px-0">
          <div class="flex h-[130px] items-center justify-center text-[90px]">
            <a href="/" @click="handleError">
              <EldonVerticalAlt1ColorReactive />
            </a>
          </div>
        </div>
      </div>
    </nav>

    <div class="container prose mx-auto mt-14 px-4 sm:px-0">
      <h1>Hoppsan!</h1>
      <template v-if="error.statusCode === 404">
        <p>
          Du har hamnat på en okänd stig. Tyvärr verkar den efterlängtade sidan vara otillgänglig för tillfället. Varför
          inte utforska andra hörn av vår digitala värld medan vi fixar det? Har du frågor eller behöver hjälp? Tveka
          inte att kontakta vår support. Tillsammans löser vi detta digitala mysterium!
        </p>
      </template>
      <template v-else>
        <p>
          Du har hamnat på en okänd stig. Tyvärr verkar det som sidan du sökte innehåller ett fel. Varför inte utforska
          andra hörn av vår digitala värld medan vi fixar det? Har du frågor eller behöver hjälp? Tveka inte att
          kontakta vår support. Tillsammans löser vi detta digitala mysterium!
        </p>
      </template>

      <ContactInfo />
      <div class="mt-10 text-center">
        <button
          class="rounded-full border-2 border-sky bg-sky px-6 py-2.5 text-base font-semibold text-white shadow-sm hover:border-sky-900 hover:bg-sky-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-900 disabled:cursor-not-allowed disabled:border-disabled-300 disabled:bg-disabled-300 disabled:text-disabled-500 disabled:shadow-none disabled:ring-0"
          @click="handleError">
          Gå tillbaka till Startsidan
        </button>
      </div>
    </div>
  </NuxtLayout>
</template>
