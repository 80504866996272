import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoIconArticle, LazySvgoIconDownload, LazySvgoIconElcentral, LazySvgoIconEluttagscentral, LazySvgoIconFaq, LazySvgoIconFordelningscentral, LazySvgoIconIndustrikontaktor, LazySvgoIconKopplingsmateriel, LazySvgoIconLaddare, LazySvgoIconLinkedIn, LazySvgoIconMatarprodukt, LazySvgoIconNormapparat, LazySvgoIconSolutionCompany, LazySvgoIconSolutionCoop, LazySvgoIconSolutionHome, LazySvgoIconSolutionPublic, LazySvgoIconTillbehor, LazySvgoIconUsp, LazyAssetDownloadList, LazyContactPersonList, LazyContentArticle, LazyContentSplitWithImage, LazyContentSwipeGrid, LazyContentTextWithIcon, LazyFaq, LazyHeroSplit2, LazyHeroSplitWithImage, LazyHeroWhiteOverlay, LazyHeroWithVideo, LazyPage, LazyPost, LazyProduct, LazyProductCategory, LazySearch } from '#components'
const lazyGlobalComponents = [
  ["SvgoIconArticle", LazySvgoIconArticle],
["SvgoIconDownload", LazySvgoIconDownload],
["SvgoIconElcentral", LazySvgoIconElcentral],
["SvgoIconEluttagscentral", LazySvgoIconEluttagscentral],
["SvgoIconFaq", LazySvgoIconFaq],
["SvgoIconFordelningscentral", LazySvgoIconFordelningscentral],
["SvgoIconIndustrikontaktor", LazySvgoIconIndustrikontaktor],
["SvgoIconKopplingsmateriel", LazySvgoIconKopplingsmateriel],
["SvgoIconLaddare", LazySvgoIconLaddare],
["SvgoIconLinkedIn", LazySvgoIconLinkedIn],
["SvgoIconMatarprodukt", LazySvgoIconMatarprodukt],
["SvgoIconNormapparat", LazySvgoIconNormapparat],
["SvgoIconSolutionCompany", LazySvgoIconSolutionCompany],
["SvgoIconSolutionCoop", LazySvgoIconSolutionCoop],
["SvgoIconSolutionHome", LazySvgoIconSolutionHome],
["SvgoIconSolutionPublic", LazySvgoIconSolutionPublic],
["SvgoIconTillbehor", LazySvgoIconTillbehor],
["SvgoIconUsp", LazySvgoIconUsp],
["AssetDownloadList", LazyAssetDownloadList],
["ContactPersonList", LazyContactPersonList],
["ContentArticle", LazyContentArticle],
["ContentSplitWithImage", LazyContentSplitWithImage],
["ContentSwipeGrid", LazyContentSwipeGrid],
["ContentTextWithIcon", LazyContentTextWithIcon],
["Faq", LazyFaq],
["HeroSplit2", LazyHeroSplit2],
["HeroSplitWithImage", LazyHeroSplitWithImage],
["HeroWhiteOverlay", LazyHeroWhiteOverlay],
["HeroWithVideo", LazyHeroWithVideo],
["Page", LazyPage],
["Post", LazyPost],
["Product", LazyProduct],
["ProductCategory", LazyProductCategory],
["Search", LazySearch],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
