import { default as _91_46_46_46slug_93b708ZIcv4xMeta } from "/working_dir/pages/[...slug].vue?macro=true";
import { default as contacto1LfXllilqMeta } from "/working_dir/pages/contact.vue?macro=true";
import { default as index2shB3lO2f4Meta } from "/working_dir/pages/nyheter/index.vue?macro=true";
import { default as _91slug_93FMcwwTH0kcMeta } from "/working_dir/pages/produkt/[slug].vue?macro=true";
import { default as _91id_93v1XTx8fYOPMeta } from "/working_dir/pages/produkt/print/[id].vue?macro=true";
import { default as searchuPhmcsZiGDMeta } from "/working_dir/pages/search.vue?macro=true";
import { default as buttons82Ag3mEbP6Meta } from "/working_dir/pages/styleguide/buttons.vue?macro=true";
import { default as iconsJ76xs6FXtuMeta } from "/working_dir/pages/styleguide/icons.vue?macro=true";
import { default as typographyIt5wdcCn0SMeta } from "/working_dir/pages/styleguide/typography.vue?macro=true";
import { default as component_45stubmdWiEqbC1cMeta } from "/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: _91_46_46_46slug_93b708ZIcv4xMeta?.name ?? "slug",
    path: _91_46_46_46slug_93b708ZIcv4xMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93b708ZIcv4xMeta || {},
    alias: _91_46_46_46slug_93b708ZIcv4xMeta?.alias || [],
    redirect: _91_46_46_46slug_93b708ZIcv4xMeta?.redirect,
    component: () => import("/working_dir/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: contacto1LfXllilqMeta?.name ?? "contact",
    path: contacto1LfXllilqMeta?.path ?? "/contact",
    meta: contacto1LfXllilqMeta || {},
    alias: contacto1LfXllilqMeta?.alias || [],
    redirect: contacto1LfXllilqMeta?.redirect,
    component: () => import("/working_dir/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: index2shB3lO2f4Meta?.name ?? "nyheter",
    path: index2shB3lO2f4Meta?.path ?? "/nyheter",
    meta: index2shB3lO2f4Meta || {},
    alias: index2shB3lO2f4Meta?.alias || [],
    redirect: index2shB3lO2f4Meta?.redirect,
    component: () => import("/working_dir/pages/nyheter/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FMcwwTH0kcMeta?.name ?? "produkt-slug",
    path: _91slug_93FMcwwTH0kcMeta?.path ?? "/produkt/:slug()",
    meta: _91slug_93FMcwwTH0kcMeta || {},
    alias: _91slug_93FMcwwTH0kcMeta?.alias || [],
    redirect: _91slug_93FMcwwTH0kcMeta?.redirect,
    component: () => import("/working_dir/pages/produkt/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93v1XTx8fYOPMeta?.name ?? "produkt-print-id",
    path: _91id_93v1XTx8fYOPMeta?.path ?? "/produkt/print/:id()",
    meta: _91id_93v1XTx8fYOPMeta || {},
    alias: _91id_93v1XTx8fYOPMeta?.alias || [],
    redirect: _91id_93v1XTx8fYOPMeta?.redirect,
    component: () => import("/working_dir/pages/produkt/print/[id].vue").then(m => m.default || m)
  },
  {
    name: searchuPhmcsZiGDMeta?.name ?? "search",
    path: searchuPhmcsZiGDMeta?.path ?? "/search",
    meta: searchuPhmcsZiGDMeta || {},
    alias: searchuPhmcsZiGDMeta?.alias || [],
    redirect: searchuPhmcsZiGDMeta?.redirect,
    component: () => import("/working_dir/pages/search.vue").then(m => m.default || m)
  },
  {
    name: buttons82Ag3mEbP6Meta?.name ?? "styleguide-buttons",
    path: buttons82Ag3mEbP6Meta?.path ?? "/styleguide/buttons",
    meta: buttons82Ag3mEbP6Meta || {},
    alias: buttons82Ag3mEbP6Meta?.alias || [],
    redirect: buttons82Ag3mEbP6Meta?.redirect,
    component: () => import("/working_dir/pages/styleguide/buttons.vue").then(m => m.default || m)
  },
  {
    name: iconsJ76xs6FXtuMeta?.name ?? "styleguide-icons",
    path: iconsJ76xs6FXtuMeta?.path ?? "/styleguide/icons",
    meta: iconsJ76xs6FXtuMeta || {},
    alias: iconsJ76xs6FXtuMeta?.alias || [],
    redirect: iconsJ76xs6FXtuMeta?.redirect,
    component: () => import("/working_dir/pages/styleguide/icons.vue").then(m => m.default || m)
  },
  {
    name: typographyIt5wdcCn0SMeta?.name ?? "styleguide-typography",
    path: typographyIt5wdcCn0SMeta?.path ?? "/styleguide/typography",
    meta: typographyIt5wdcCn0SMeta || {},
    alias: typographyIt5wdcCn0SMeta?.alias || [],
    redirect: typographyIt5wdcCn0SMeta?.redirect,
    component: () => import("/working_dir/pages/styleguide/typography.vue").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/home",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkt-kategori/elbilsladdare/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkt-kategori/elcentraler/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkter/elbilsladdare/laddbox/duo-smart/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkt-kategori/fordelningscentraler/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkt-kategori/elbilsladdare/laddbox/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkter/matarprodukter/matarskap/las-aldre-matarskap-2/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkter/elbilsladdare/laddbox/one-smart/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkter/elbilsladdare/laddbox/laddbox-one-combo-smart/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkt-kategori/kopplingsmateriel/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkt-kategori/elcentraler/normcentraler/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkter/elcentraler/normcentraler/dbq-normcentral/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkt-kategori/matarprodukter/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkt-kategori/matarprodukter/matarskap/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkt-kategori/fordelningscentraler/centralsystemet-hpk/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkt-kategori/normapparater/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkt-kategori/elcentraler/gangsakringscentraler/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkt-kategori/fordelningscentraler/normel/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkter/elbilsladdare/tillbehor-elbilsladd/lastbalansering/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkter/elbilsladdare/laddbox/duo-combo-smart/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkter/matarprodukter/dorr-ram-matarskap/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/om-eldon-installation/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkt-kategori/elcentraler/page/2/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkter/fordelningscentraler/centralsystemet-hpk/hgb-hgc-sakringscentraler/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkter/matarprodukter/matarskap/norminsats-matarskap-utgaende/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkt-kategori/fordelningscentraler/page/2/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkter/matarprodukter/matarskap/infallt-matarskap-mim/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkter/fordelningscentraler/normel/elkapsling-nk/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkter/elcentraler/kombicentraler/kombicentral/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkt-kategori/elbilsladdare/dc-snabbladdare/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkt-kategori/fordelningscentraler/page/3/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkt-kategori/matarprodukter/matartavlor/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkter/elbilsladdare/laddstolpar/station-smart/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkter/fordelningscentraler/centralsystemet-hpk/lastbrytare-sakring/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkt-kategori/elbilsladdare/laddstolpar/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkt-kategori/eluttagscentraler/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkt-kategori/elcentraler/page/3/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkter/elcentraler/lantbrukscentraler/nrga/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkt-kategori/kopplingsmateriel/page/2/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkter/kopplingsmateriel/anslutningsklammor/kopplingsklamma-kkl/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkter/elbilsladdare/laddstolpar/laddstation-move/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkter/matarprodukter/matarskap/norminsats-mastarskap/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkter/normapparater/belastningsvakter/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubmdWiEqbC1cMeta?.name ?? undefined,
    path: component_45stubmdWiEqbC1cMeta?.path ?? "/produkter/matarprodukter/matarskap/matarskap-muk/",
    meta: component_45stubmdWiEqbC1cMeta || {},
    alias: component_45stubmdWiEqbC1cMeta?.alias || [],
    redirect: component_45stubmdWiEqbC1cMeta?.redirect,
    component: () => import("/working_dir/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]