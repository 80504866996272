import { Logger } from '~/composables/logger'

export default defineNuxtPlugin({
  name: 'cookie-consent',
  setup() {
    const gtm = useGtm()
    const { state } = useCookieConsent()

    gtm.enable(state.value.statistic && state.value.marketing)

    Logger.info('CookieConsent: ', state.value, gtm.enabled())
  },
})
