/* eslint-disable no-console */

export type LogEntryVariant = 'error' | 'warn' | 'info' | 'debug' | 'none'

export const getLoggerWithVerbosity = () => {
  const runtimeConfig = useRuntimeConfig()
  const verbosity = runtimeConfig.public.logger.level || 'error'

  // Use a catch-all if the logger.level is higher than the logging
  const noOperation = () => {}

  const matches = (verbosities: LogEntryVariant[]) => verbosities.includes(verbosity)

  return {
    debug: matches(['debug']) ? console.debug : noOperation,
    info: matches(['debug', 'info']) ? console.info : noOperation,
    warn: matches(['debug', 'info', 'warn']) ? console.warn : noOperation,
    error: matches(['debug', 'info', 'warn', 'error']) ? console.error : noOperation,
  }
}
