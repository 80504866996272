<script setup lang="ts">
import { provideUseId } from '@headlessui/vue'
import { Logger } from '~/composables/logger'

const { state } = useCookieConsent()
const gtm = useGtm()

// Using the composable in your script
const allowedStatisticsAndMarketing = computed(() => {
  return state.value.statistic && state.value.marketing
})

watch(allowedStatisticsAndMarketing, () => {
  if (gtm && allowedStatisticsAndMarketing) {
    gtm.enable(state.value.statistic && state.value.marketing)

    Logger.info('CookieConsent: ', state.value, gtm.enabled())
  }
})

// HOTFIX until Vue v3.5: https://github.com/tailwindlabs/headlessui/issues/2913#issuecomment-1922330799
provideUseId(() => useId())

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Eldon Installation` : 'Eldon Installation'
  },
})
</script>

<template>
  <div>
    <NuxtLoadingIndicator color="#244bf5" />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
