<script setup lang="ts"></script>
<template>
  <ul>
    <li>
      Telefon växel:
      <a href="tel:010-206 77 11">010-206 77 11</a>
    </li>
    <li>
      Kundservice:
      <a href="mailto:support@eldoninstallation.se">support@eldoninstallation.se</a>
    </li>
    <li>
      Beställningar:
      <a href="mailto:order@eldoninstallation.se">order@eldoninstallation.se</a>
    </li>
    <li>
      Offertförfrågan:
      <a href="mailto:offert@eldoninstallation.se">offert@eldoninstallation.se</a>
    </li>
  </ul>
</template>
