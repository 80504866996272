import { getStyledConsolePrefix } from './style'
import { getLoggerWithVerbosity } from '~/composables/logger/verbosity'

const isNode: boolean =
  Object.prototype.toString.call(typeof process !== 'undefined' ? process : 0) === '[object process]'

export const Logger = {
  error: (err, message?: string) => {
    const nuxtApp = useNuxtApp()
    if (isNode && process?.server && nuxtApp.$logger) {
      nuxtApp.$logger.log('error', message, err)
    } else {
      getLoggerWithVerbosity().error(...getStyledConsolePrefix('error'), message, err)
    }
  },

  warn: (message?: string, ...args) => {
    const nuxtApp = useNuxtApp()
    if (isNode && process?.server && nuxtApp.$logger) {
      nuxtApp.$logger.log('warn', message, { data: args })
    } else {
      getLoggerWithVerbosity().warn(...getStyledConsolePrefix('warn'), message, ...args)
    }
  },

  info: (message?: string, ...args) => {
    const nuxtApp = useNuxtApp()
    if (isNode && process?.server && nuxtApp.$logger) {
      nuxtApp.$logger.log('info', message, { data: args })
    } else {
      getLoggerWithVerbosity().info(...getStyledConsolePrefix('info'), message, ...args)
    }
  },

  debug: (message?: string, ...args) => {
    const nuxtApp = useNuxtApp()
    if (isNode && process?.server && nuxtApp.$logger) {
      nuxtApp.$logger.log('debug', message, { data: args })
    } else {
      getLoggerWithVerbosity().debug(...getStyledConsolePrefix('debug'), message, ...args)
    }
  },
}
