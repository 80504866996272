export default defineAppConfig({
  externalProductLinks: {
    ahlsell:
      'https://www.ahlsell.se/search?parameters.SearchPhrase=eldon+installation&parameters.Facets%5Bstatic_virtual_brand%5D=Eldon+Installation&parameters.Facets%5Bstatic_virtual_brand%5D=Eldon',
    solar: 'https://www.solar.se/',
    elektroskandia: 'https://www.elektroskandia.se/',
    rexel: 'https://www.rexel.se/',
    onninen: 'https://www.onninen.se/search?term=eldon&filter=brand%3DELDON&sort=_score&order=DESC',
  },

  productSpecification: {
    skipParametricGroups: ['', 'USP', 'external_links', 'navision_parametric_group'],
  },
})
